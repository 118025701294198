import React from 'react'
import classNames from 'classnames'
import { CopyIcon } from '@/atoms/Icons/CopyIcon'
import { EnvelopeIcon } from '@/atoms/Icons/EnvelopeIcon'
import { FacebookIcon } from '@/atoms/Icons/FacebookIcon'
import { LinkIcon } from '@/atoms/Icons/LinkIcon'
import { LinkedInIcon } from '@/atoms/Icons/LinkedInIcon'
import { RedditIcon } from '@/atoms/Icons/RedditIcon'
import { TelegramIcon } from '@/atoms/Icons/TelegramIcon'
import { TwitterXIcon } from '@/atoms/Icons/TwitterXIcon'
import { LabelSM, TitleMD, LabelMD } from '@/atoms/Text'
import { IconColor, OneColorIconProps } from '@/atoms/utils'
import { paths } from '@/constants/paths'
import { useToast } from '@/molecules/Toast'
import { useTranslate } from '@/utils/translate/translate-client'

interface SocialSharingProps {
  color?: IconColor
  url?: string
  vertical?: boolean
  onShare?: (network: string) => void
  iconWrapperClassName?: string
}

interface SocialShare {
  name: string
  icon: React.FC<OneColorIconProps>
  href?: string
  onClick?: () => void
  brandColor: string
}

interface UseSocialSharingValues {
  links: SocialShare[]
  socials: Record<string, SocialShare>
}

export const useSocialSharing = ({ url }: { url: string }): UseSocialSharingValues => {
  const { t } = useTranslate('common')

  const linkCopied = t('linkCopied', 'Link copied.')

  const toast = useToast()
  const socials: Record<string, SocialShare> = {
    copyLink: {
      name: t('copyLink', 'Copy Link'),
      icon: LinkIcon,
      onClick: () => {
        navigator.clipboard.writeText(url)
        toast?.showToast(linkCopied, { icon: <CopyIcon size={24} color="gray-200" /> })
      },
      brandColor: '#000',
    },
    email: {
      name: t('email', 'Email'),
      icon: EnvelopeIcon,
      href: `mailto:?subject=Join%20me%20on%20Angel%20Studios&body=Come%20and%20see%20this%20from%20Angel%20Studios:%0D%0D${url}`,
      brandColor: '#000',
    },
    twitter: {
      name: 'Twitter',
      icon: TwitterXIcon,
      href: `https://twitter.com/share?url=${url}`,
      brandColor: '#1DA1F2',
    },
    facebook: {
      name: 'Facebook',
      icon: FacebookIcon,
      href: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      brandColor: '#1877F2',
    },
    reddit: {
      name: 'Reddit',
      icon: RedditIcon,
      href: `http://reddit.com/submit?url=${url}`,
      brandColor: '#FF4500',
    },
    telegram: {
      name: 'Telegram',
      icon: TelegramIcon,
      href: `https://telegram.me/share/url?url=${url}`,
      brandColor: '#27A7E7',
    },
    linkedIn: {
      name: 'LinkedIn',
      icon: LinkedInIcon,
      href: `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
      brandColor: '#0A66C2',
    },
  }

  return {
    socials,
    links: Object.values(socials),
  }
}

export const SocialSharing: React.FC<SocialSharingProps> = ({
  color = 'gray-700',
  url = encodeURI(`${paths.base}`),
  onShare,
  iconWrapperClassName,
}) => {
  const { links, socials } = useSocialSharing({ url })
  const { t } = useTranslate('common')

  const renderSocialIcon = ({ name, href, onClick, icon: Icon }: SocialShare) => {
    if (name === 'Copy Link') return null

    return (
      <div className="mb-6">
        <a
          key={name}
          className={classNames(
            'focus-visible:right-offset-0 flex flex-col items-center gap-2 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-transparent',
            iconWrapperClassName,
          )}
          href={href}
          rel="noreferrer"
          target="_blank"
          onClick={() => {
            onShare?.(name)
            onClick?.()
          }}
          aria-label={name}
        >
          <div className="rounded-full bg-gray-100 p-4">
            <Icon color={color} size={28} />
          </div>
          <LabelSM className={classNames('text-center pt-2', `text-${color}`)}>{name}</LabelSM>
        </a>
      </div>
    )
  }
  return (
    <div className="mx-auto mt-[-2.5em]  w-full p-4">
      <div className="mb-4 flex items-center justify-between pb-4">
        <TitleMD className={`text-${color}`}> {t('share', 'Share')}</TitleMD>
      </div>
      <div className="grid grid-cols-3 gap-x-24">
        {links.filter((link) => link.name !== 'Copy Link').map(renderSocialIcon)}
      </div>
      <div className="mt-2">
        <LabelMD className="mb-2 text-sm">{t('pageLink', 'Page Link')}</LabelMD>
        <div className="flex items-center rounded-lg bg-gray-100 p-2">
          <input type="text" value={url} readOnly className="grow bg-transparent text-sm outline-none" />
          <button
            onClick={() => {
              socials.copyLink.onClick?.()
              onShare?.('Copy to Clipboard')
            }}
            className="ml-2 rounded p-1 hover:bg-gray-200"
            aria-label="Copy link"
          >
            <CopyIcon size={20} color={color} />
          </button>
        </div>
      </div>
    </div>
  )
}
