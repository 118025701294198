import { gql as contentfulGQL } from '@apollo/client'
import { getSharedContentfulClient } from '@/services/ApolloClient/ApolloClient'
import { GetTheatricalPromoQuery, GetTheatricalPromoQueryVariables, TheatricalPromo } from '@/types/codegen-contentful'

const getTheatricalPromoQuery = contentfulGQL/* GraphQL */ `
  query getTheatricalPromo($slug: String, $preview: Boolean) {
    theatricalPromoCollection(limit: 1, where: { slug: $slug }, preview: $preview) {
      items {
        slug
        type
        code
        flag
        pendingReservationDiscountName
        discount
        startDate
        endDate
        experimentDataCollection {
          ... on TheatricalPromoExperimentDataCollection {
            items {
              discount
              variationName
              experimentName
              pendingReservationDiscountName
              code
              flag
              type
              slug
            }
          }
        }
      }
    }
  }
`
export const TicketPromoTypes = {
  FlashSale: 'FLASH_SALE',
  EarlyBird: 'EARLY_BIRD',
  NoFees: 'NO_FEES',
  June19: 'JUNE_19',
  Bogo: 'BOGO',
  Collectibles: 'COLLECTIBLES',
}

export interface TheatricalPromoType extends TheatricalPromo {
  isExperiment: boolean
  experimentName?: string | null
}
export interface TheatricalExperimentClientOptions {
  locale?: string
  preview?: boolean
  slug: string
}

export async function getTheatricalPromo(opts: TheatricalExperimentClientOptions): Promise<TheatricalPromoType | null> {
  const { data } = await getSharedContentfulClient(opts).query<
    GetTheatricalPromoQuery,
    GetTheatricalPromoQueryVariables
  >({
    query: getTheatricalPromoQuery,
    variables: { preview: opts?.preview, slug: opts.slug },
  })
  const promo = data?.theatricalPromoCollection?.items?.[0] as TheatricalPromo
  const isExperiment = !!promo?.experimentDataCollection?.items && promo?.experimentDataCollection?.items?.length > 0
  const experimentName = promo?.experimentDataCollection?.items?.[0]?.experimentName ?? null

  return { ...promo, isExperiment, experimentName }
}
