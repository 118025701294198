import { isAfter, isBefore, isEqual } from 'date-fns'
import { TheatricalPromoType } from '@/services/Theatrical/contentfulQueries'

export const isTheatricalPromoValid = (promo: TheatricalPromoType | undefined) => {
  if (!promo) return false

  const startDate = new Date(promo?.startDate)
  const endDate = promo?.endDate ? new Date(promo?.endDate) : null
  const today = new Date()

  const isTodayEqualOrAfterStartDate = isEqual(startDate, today) || isAfter(today, startDate)

  if (!endDate && isTodayEqualOrAfterStartDate) return true

  const isTodayEqualOrBeforeEndDate = isEqual(endDate as Date, today) || isBefore(today, endDate as Date)

  return isTodayEqualOrAfterStartDate && isTodayEqualOrBeforeEndDate
}
