import { FC, HTMLAttributes } from 'react'
import { CloseIcon } from '@/atoms/Icons/CloseIcon'
import { Modal } from '@/molecules/Modal'
import { SocialSharing } from '@/organisms/SocialSharing'

interface SharingModalProps extends HTMLAttributes<HTMLElement> {
  onClose: () => void
  open: boolean
  url: string
}

export const SharingModal: FC<SharingModalProps> = ({ className, onClose, open, url }) => {
  return (
    <Modal isOpen={open} onClose={onClose} transition="slide" className={className} panelClasses="!max-w-[480px] ">
      <div className="m-4">
        <div className="flex cursor-pointer flex-row justify-end" onClick={onClose}>
          <CloseIcon size={24} />
        </div>
        <SocialSharing url={url} vertical={true} />
      </div>
    </Modal>
  )
}
